import * as React from 'react';
import { Box, Button,} from '@mui/material'
import { StateMenuDesktop } from './sub-menu/state-menu';
import { RaceMenuDesktop } from './sub-menu/race-menu';
import { Link } from 'react-router-dom'



const ResponsiveAppBar = (props) => {
  

  return (
    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'space-evenly', }}>



    {/**<Button sx={{ my: 2, color: 'white', display: 'block' }} onClick={()=>navigate('/')}>
      Startseite
  </Button>*/}


    <Button sx={{ my: 2, color: 'white', display: 'block' }} to='/Suche' component={Link}>
      Suche
    </Button>

    <RaceMenuDesktop breeds={props.breeds} sx={{ my: 2, color: 'white', display: 'block' }}/>

    <StateMenuDesktop sx={{ my: 2, color: 'white', display: 'block' }}/>

    <Button sx={{ my: 2, color: 'white', display: 'block' }} to='/Artikel' component={Link}>
      Artikel
    </Button>

  </Box>
  );
};
export default ResponsiveAppBar;
